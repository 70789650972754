<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
let user = JSON.parse(localStorage.getItem("user"));
/**
 * Invoice-detail component
 */
export default {
  page: {
    title: "Chi tiết đơn hàng",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Chi tiết đơn hàng",
      items: [
        {
          text: "Trang chủ",
          href: "/",
        },
        {
          text: "Chi tiết đơn hàng",
          active: true,
        },
      ],
      order: null,
    };
  },

  methods: {
    deleteOrder(_id) {
      axios({
        url: process.env.VUE_APP_SERVER_URL + "/api/order/delete",
        method: "post",
        headers: {
          authorization: user.token,
        },
        data: {
          _id: _id,
        },
      })
        .then(({ data }) => {
          if (data) {
            this.$toasted.success("Xóa bản ghi thành công");
            this.$router.push("/danh-sach-don-hang");
          }
        })
        .catch((err) => {
          this.$toasted.error("Có lỗi không thể xóa bản ghi này.");
        });
    },
  },

  created() {
    if (!this.$route.query.id) {
      this.$router.push("/");
    }
    let _id = this.$route.query.id;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/order/detail",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          this.order = data;
        } else {
          this.$toasted.error("Không tìm thấy đơn hàng phù hợp");
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.$toasted.error("Không tìm thấy đơn hàng phù hợp");
        this.$router.push("/");
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="order">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <h4 class="float-end font-size-16">
                Mã đơn # {{ order.invoice }}
              </h4>
              <div class="mb-4">
                <img
                  src="@/assets/images/logo-dark.png"
                  alt="logo"
                  height="20"
                />
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <address>
                  <strong>Người gửi:</strong>
                  <br />{{ order.sender_fn }} <br />
                  {{ order.sender_email }} <br />
                  {{ order.sender_phone }}
                  <br />
                  {{ order.sender_address }}
                </address>
              </div>
              <div class="col-6 text-sm-end">
                <address>
                  <strong>Gửi đến:</strong>
                  <br />{{ order.recipient_fn }} <br />
                  {{ order.recipient_email }} <br />
                  {{ order.recipient_phone }}
                  <br />
                  {{ order.recipient_address }}
                </address>
              </div>
            </div>
         
            <div class="p-2 mt-3">
              <h3 class="font-size-16">Nội dung đơn hàng</h3>
            </div>
            <div class="table-responsive">
              <!-- {{order.product}} -->
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 70px">No.</th>
                    <th>Item</th>
                    <th class="text-sm-end">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in order.products" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td class="text-sm-end">{{ item.price }}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div class="d-print-none">
              <div class="float-end">
                <!-- <a
                  href="javascript:window.print()"
                  class="btn btn-success waves-effect waves-light me-1"
                  ><i class="fa fa-print"></i
                ></a> -->
                <router-link :to="{ name: 'bill-add', query: { id: order._id } }" target= '_blank'
             class="btn btn-primary w-md waves-effect waves-light"
               
                 
                  >Xử lý đơn hàng</router-link
                >
                <button
                  type="button"
                  @click="deleteOrder(order._id)"
                  class="btn btn-danger mx-1 w-md waves-effect waves-light"
                >
                  Xóa đơn hàng
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>